import { FlexibleContent, HeroSlider } from '@/templates';

import type { FunctionComponent } from 'react';

export type TypePageProps = {
  hero: any;
  flexible: any[];
};

export const Page: FunctionComponent<TypePageProps> = ({
  flexible,
  hero,
}: TypePageProps) => (
  <>
    {hero && <HeroSlider hero={hero} />}
    <FlexibleContent type="Page" flexible={flexible} />
  </>
);

Page.displayName = 'Page';
